import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import useAlert from "../hooks/useAlert";
import Alert from "../components/Alert";

const Contact = () => {
  const [form, setForm] = useState({ name: "", email: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const { alert, showAlert, hideAlert } = useAlert();
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_KEY,
        process.env.REACT_APP_TEMPLATE_KEY,
        {
          from_name: form.name,
          to_name: "Nikola",
          from_email: form.email,
          to_email: "nikolavekic1997@gmail.com",
          message: form.message,
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(() => {
        setIsLoading(false);
        // show success message
        // hide alert
        showAlert({
          show: true,
          text: "Message sent successfully!",
          type: "success",
        });

        setTimeout(() => {
          hideAlert(false);
          setForm({ name: "", email: "", message: "" });
        }, [2000]);
      })
      .catch((err) => {
        setIsLoading(false);
        showAlert({
          show: true,
          text: "Message not received.",
          type: "danger",
        });
      });
  };
  return (
    <section className="flex lg:flex-row gap-x-8 lg:gap-x-16 flex-col max-container">
      {alert.show && <Alert {...alert} />}
      <div className="w-full flex flex-col pt-20 sm:pt-32">
        <h1 className="text-3xl lg:text-4xl font-bold mb-8">Get in Touch</h1>
        <form className="w-full flex flex-col gap-6" onSubmit={handleSubmit}>
          {/* ... (form inputs) */}
          <label className="text-black-500 font-semibold">
            Name
            <input
              type="text"
              name="name"
              className="input"
              placeholder="Your Name"
              required
              value={form.name}
              onChange={handleChange}
            />
          </label>
          <label className="text-black-500 font-semibold">
            Email
            <input
              type="email"
              name="email"
              className="input"
              placeholder="Your Email"
              required
              value={form.email}
              onChange={handleChange}
            />
          </label>
          <label className="text-black-500 font-semibold">
            Your Message
            <textarea
              type="text"
              name="message"
              className="textarea"
              placeholder="Your Message"
              required
              rows={4}
              value={form.message}
              onChange={handleChange}
            />
          </label>
          <button disabled={isLoading} type="submit" className="btn">
            {isLoading ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
