import React from "react";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <section className="cta">
      <p className="cta-text text-black font-medium">
        Have a project in mind? <br className="sm:block hidden" />
        Lets work together!
      </p>
      <button className="btn">
        <Link to="/contact">Contact</Link>
      </button>
    </section>
  );
};

export default CTA;
