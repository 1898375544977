import {
  car,
  contact,
  css,
  chat,
  express,
  sql,
  github,
  html,
  javascript,
  linkedin,
  mongodb,
  nodejs,
  blog,
  react,
  typescript,
  bootstrap,
  summiz,
  tailwindcss,
  travel,
  bootcamp,
  menu,
} from "../assets/icons";

export const skills = [
  {
    imageUrl: css,
    name: "CSS",
    type: "Frontend",
  },
  {
    imageUrl: express,
    name: "Express",
    type: "Backend",
  },
  {
    imageUrl: github,
    name: "GitHub",
    type: "Version Control",
  },
  {
    imageUrl: html,
    name: "HTML",
    type: "Frontend",
  },
  {
    imageUrl: javascript,
    name: "JavaScript",
    type: "Frontend",
  },
  {
    imageUrl: mongodb,
    name: "MongoDB",
    type: "Database",
  },
  {
    imageUrl: nodejs,
    name: "Node.js",
    type: "Backend",
  },
  {
    imageUrl: react,
    name: "React",
    type: "Frontend",
  },
  {
    imageUrl: typescript,
    name: "TypeScript",
    type: "Frontend",
  },
  {
    imageUrl: sql,
    name: "SQL",
    type: "Backend",
  },
  {
    imageUrl: bootstrap,
    name: "Bootstrap",
    type: "Frontend",
  },
  {
    imageUrl: tailwindcss,
    name: "Tailwind CSS",
    type: "Frontend",
  },
];

export const nav = { iconUrl: menu };

export const socialLinks = [
  {
    name: "Contact",
    iconUrl: contact,
    link: "/contact",
  },
  {
    name: "GitHub",
    iconUrl: github,
    link: "https://github.com/NikolaVekic",
  },
  {
    name: "LinkedIn",
    iconUrl: linkedin,
    link: "https://www.linkedin.com/in/web-nikolavekic/",
  },
];

export const projects = [
  {
    iconUrl: chat,
    theme: "btn-back-red",
    name: "Messenger App",
    description:
      "Fast and secure chat application, it includes features for user authentication and real-time chat capabilities.",
    link: "https://github.com/NikolaVekic/messenger-app",
  },
  {
    iconUrl: blog,
    theme: "btn-back-green",
    name: "Nature Journal",
    description:
      "News website designed for wilderness education, built to demonstrade fullstack capabilities and a responsive design.",
    link: "https://github.com/NikolaVekic/react-blog",
  },
  {
    iconUrl: travel,
    theme: "btn-back-blue",
    name: "Japan Travel Blog",
    description:
      "Travel blog for sharing trip experiences, the website includes CRUD features, authentication and custom data storage. ",
    link: "https://github.com/NikolaVekic/node-blog",
  },
  {
    iconUrl: bootcamp,
    theme: "btn-back-pink",
    name: "Frontend Bootcamp",
    description:
      "Landing page for a frontend development bootcamp, built entirely using Bootstrap for a consistent design.",
    link: "https://github.com/NikolaVekic/bootstrap-landing-page",
  },
  {
    iconUrl: summiz,
    theme: "btn-back-black",
    name: "NexusAI",
    description:
      "Landing page for a modern AI company, built with reusable react components for a clean and responsive website.",
    link: "https://github.com/NikolaVekic/react-landing-page",
  },
  {
    iconUrl: car,
    theme: "btn-back-yellow",
    name: "Car Rental App",
    description:
      "Car rental app made for browsing multiple cars before booking, build to demonstrate rapid API functionality and data manipulation.",
    link: "https://github.com/NikolaVekic/car-rental-app",
  },
];
