import React from "react";
import { skills } from "../constants";

const About = () => {
  return (
    <section className="section-container flex flex-col justify-between">
      <div>
        <h1 className="head-text">
          Hello, I'm{" "}
          <span className="blue-gradient_text font-semibold ">Nikola</span>
        </h1>
        <div className="mt-5 flex flex-col gap-3 leading-8 text-slate-600 sm:text-lg">
          I would describe myself as resourceful in finding solutions to
          challenges and always having a positive outlook on things. My journey
          started with a simple curiosity of how websites work and quickly
          evolved into a passion for creating amazing web experiences. With
          expertise in web technologies I've had the opportunity to work on
          diverse projects—from small business websites to larger scale web
          applications—each time aiming to better myself through constant
          practice. I'm excited to continue my journey, exploring innovative
          ways to bring ideas to life.
        </div>
        <div className="py-14 flex flex-col">
          <h3 className="subhead-text">Skills</h3>
          <div className="mt-16 flex flex-wrap gap-24 md:gap-32 items-center justify-evenly">
            {skills.map((skill) => (
              <div key={skill.name} className="flex flex-col items-center">
                <div className="block-container w-20 h-20">
                  <div className="btn-back rounded-xl" />
                  <div className="btn-front rounded-xl flex justify-center items-center">
                    <img
                      src={skill.imageUrl}
                      alt={skill.name}
                      className="w-1/2 h-1/2 object-contain"
                    />
                  </div>
                </div>
                <p className="pt-2">{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
