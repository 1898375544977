import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Footer, Navbar } from "./components";
import { About, Contact, Home, Projects } from "./pages";
import { ScrollProvider, useScroll } from "./components/ScrollContext";

const AppContent = () => {
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);

  // Directly using `useScroll` within the component that is a child of `ScrollProvider`
  const { scrollTo, setScrollTo } = useScroll();

  // Function to execute the scrolling based on the `scrollTo` value
  useEffect(() => {
    if (scrollTo === "about" && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollTo(null); // Reset the scrollTo state after scrolling
    } else if (scrollTo === "projects" && projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollTo(null);
    } else if (scrollTo === "home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setScrollTo(null);
    }
  }, [scrollTo, setScrollTo]);

  return (
    <main className="bg-slate-50">
      <Navbar aboutRef={aboutRef} projectsRef={projectsRef} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home />
              <div ref={aboutRef}>
                <About />
              </div>
              <div ref={projectsRef}>
                <Projects />
              </div>
            </>
          }
        />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </main>
  );
};

const App = () => {
  return (
    <ScrollProvider>
      <Router>
        <AppContent />
      </Router>
    </ScrollProvider>
  );
};

export default App;
